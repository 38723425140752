
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Deal, Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { fixPrice, isValidNumber, parseToNumber, stringifySafe, updateNestedObject } from '@/utils/general'
  import { Payment, PaymentOrder } from '@/entities/finance'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import logOut from '@/components/LogOut.vue'
  import { ItemType, SaleOrder, SaleOrderItem } from '@/entities/sales'
  import { SaleOrderView } from '@/components/forms/view/SaleOrderView'
  import GCostField from '@/components/core/input/GCostField.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { Debounce } from '@/utils/decorators'
  import { Person } from '@/entities/persons'
  import { ProcessTrafficTicket } from '@/entities/purchase'
  import ProgressCell from '@/components/dataTables/cell/ProgressCell.vue'

@Component({
  components: {
    ProgressCell,
    GRadioButton,
    Agenda,
    GDatePicker,
    GCostField,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
  methods: {},
})
  export default class SaleOrderForm extends SaleOrderView {
  loaded = false
  transferCost = 0
  deal = plainToInstance(Deal, {})
  payments: Array<Payment> = []
  lead = plainToInstance(Lead, {})
  saleOrder = plainToInstance(SaleOrder, {})
  process: Process = plainToInstance(Process, {})
  paymentOrder: PaymentOrder = plainToInstance(PaymentOrder, {})
  minValue = null
  maxDays = null
  newAgenda = null
  activity = null
  isMultiple = false
  activities = []
  currencyExchanges = {}
  penalties: ProcessTrafficTicket[] = []
  penaltiesErrorMessage = ''
  purchase = {
    open: false,
    title: '',
    item: null,
  }

  saleOrderItemDiscountId = null
  products = []
  errorDiscount = ''
  errorSignerMessages = ''
  errorAcquirerMessages = ''
  errorMessages = ''
  errorDiscountPostFinancing = ''
  warrantyAmountSet = 0
  saleMetadata = null
  discountPostFinancing = 0
  title = ''
  idProcess = null
  showDetail = false
  fileInfo = null
  panels = [0]
  section = {
    interviews: false,
    documents: false,
    payment: false,
  }

  declare $refs: {
    form: HTMLFormElement
    fieldSigners: LinkedPerson
    fieldClient: LinkedPerson
    fieldAcquirer: LinkedPerson
  };

  warrantyAmount = null
  metadata = {}
  formData = {
    client: null,
    discountPostFinancing: null,
    discount: null,
    order: null,
    isWarranty: null,
    insurance: null,
    broker: null,
    isTag: null,
    deductible: null,
    cost: null,
    warranty: null,
    signers: null,
    acquirer: null,
    documentType: null,
    fileWarranty: [],
    comment: null,
    fileExtraWarranty: [],
    fileTag: [],
    fileCav: [],
    filePenalties: [],
    fileLicense: [],
    fileLegalReport: [],
    linkLegalReport: null,
    contract: [],
    linkContract: null,
  }

  fields = {
    warranties: {
      properties: {
        label: 'Garantia',
        required: true,
        itemText: 'warrantyDescription',
        itemValue: 'id',
        rules: [],
      },
      items: [],
      rules: [],
    },
    deductible: {
      properties: {
        label: 'Deducible',
        required: true,
        itemText: 'description',
        itemValue: 'id',
        rules: [],
      },
      items: [],
      rules: [],
    },
    insurance: {
      properties: {
        label: 'Compañia de seguros',
        required: true,
        rules: [],
        itemText: 'alias',
        itemValue: 'id',
        returnObject: true,
      },
      items: [],
      rules: [],
    },
    brokers: {
      properties: {
        label: 'Corredora de seguros',
        required: true,
        rules: [],
        itemText: 'alias',
        itemValue: 'id',
        returnObject: true,
      },
      items: [],
      rules: [],
    },
    client: {
      properties: {
        required: true,
        disabled: true,
        properties: {
          label: 'Cliente',
          rules: [],
          itemText: 'formInfo',
          itemValue: 'id',
          returnObject: true,
        },
      },
      items: [],
      rules: [],
    },
    acquirer: {
      properties: {},
      items: [],
      rules: [],
    },
    signers: {
      properties: {},
      items: [],
      rules: [],
    },
    documentType: {
      properties: {},
      rules: null,
      items: [],
    },
    linkContract: {
      properties: {},
    },
    linkLegalReport: {
      properties: {},
    },
    order: {
      properties: {
        label: 'Abonos',
        required: true,
        readOnly: true,
        rules: [],
        appendOuterIcon: '',
      },
    },
  }

  disabledFormSelection = {
    warranty: false,
    tag: false,
  }

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()
    const { id, uid, model, title, saleMetadata } = this

    if ((!isNaN(uid) && (model === 'SaleOrder' || model === '')) || !isNaN(id)) {
      const saleId = id || uid
      await this.getSellInfo(saleId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(saleMetadata, title, Boolean(this.saleOrder?.id))
    }

    await this.setTheBackup()
    this.setDetails()
    this.loadingForm = false
  }

  async setTheBackup () {
    const { backup } = this
    if (!backup) return
    const saleForm = 'saleForm' in backup && backup.saleForm

    if (saleForm) {
      this.formData = backup.saleForm
      this.panels = backup.saleForm.panels
    }

    this.formData.order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_deal: { _eq: this.saleOrder?.deal?.id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })
    this.setPersons(backup)
  }

  setPersons (backup) {
    if ('acquirer' in backup && backup.acquirer) {
      const backupAcquirer = backup.saleForm?.acquirer || []
      const acquirers = [backup.acquirer, ...backupAcquirer?.filter(acquirer => acquirer.id !== backup.acquirer.id)].filter(Boolean)
      this.formData.acquirer = acquirers
      this.fields.acquirer.items = acquirers
    }

    if ('signers' in backup && backup.signers) {
      const backupSigners = backup.saleForm?.signers || []
      const signers = [backup.signers, ...backupSigners?.filter(signer => signer.id !== backup.signers.id)].filter(Boolean)
      this.formData.signers = signers
      this.fields.signers.items = signers
    }
  }

  cancelSale () {
    const { formData: { fileCav, fileLicense, filePenalties, fileExtraWarranty, fileWarranty, fileTag, contract } } = this
    this.setBackup(null)
    const files = [...fileCav, ...fileLicense, ...filePenalties, ...fileExtraWarranty, ...fileWarranty, ...fileTag, ...contract]

    this.close(files)
  }

  async getSellInfo (id) {
    this.saleOrder = await this.fetchData({
      query: { name: 'fetch', model: 'SaleOrder', params: { id } },
      force: true,
    })

    const lead = await this.fetchData({
      query: { name: 'fetch', model: 'Lead', params: { id: this.saleOrder?.deal?.lead?.id } },
      force: true,
    })

    const idDeal = this.saleOrder?.deal?.id

    this.formData.order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_deal: { _eq: idDeal } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    const acquirers = this.saleOrder.document?.acquirers?.length ? this.saleOrder.document?.acquirers : this.saleOrder?.deal?.stock?.acquirer
    this.formData.acquirer = acquirers
    this.fields.acquirer.items = acquirers?.length ? acquirers : [lead.client]
    await this.getAdditionalInfo()
    this.transferCost = await this.calculateTransfer(this.saleOrder?.deal?.stock?.id)
    this.saleOrder.deal.lead = lead
    const { sale: { order: { discount } } } = lead
    this.setLeadInfo(lead, discount)
  }

  async getAdditionalInfo () {
    this.fields.insurance.items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { company_type: { name: { _eq: 'insurance_carrier' } } },
      limit: 100,
    })
    const brokers = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { company_type: { name: { _eq: 'broker' } } },
    })

    this.fields.brokers.items = brokers
    const deductibles = await this.fetchData({
      query: { name: 'find', model: 'DeductibleType' },
    })
    this.fields.deductible.items = deductibles
    const products = await this.fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: this.saleOrder.id } },
      force: true,
    })

    const insurance = products?.find(product => product.name === 'insurance')
    this.formData.insurance = this.fields.insurance.items?.find(insuranc => insuranc.id === insurance?.company?.id)
    this.formData.broker = brokers?.find(broker => broker.id === insurance?.broker?.id)
    this.formData.cost = insurance?.cost
    this.formData.deductible = deductibles?.find(deductible => deductible.id === insurance?.deductible?.id)?.id

    const tag = products?.find(product => product.name === 'tag')

    const warranty = products?.find(product => product.name === 'warranty')
    this.warrantyAmountSet = warranty?.cost

    if (tag) {
      this.formData.isTag = true
    } else if (insurance) {
      this.formData.isTag = false
    }
    this.products = products || []

    const supports = await this.fetchData({
      query: { name: 'find', model: 'SupportDocumentType' },
    })

    this.fields.documentType.items = supports

    this.formData.documentType = supports.find(support => support.name === this.saleOrder?.stockDocumentType)
    await this.setFiles(tag, products?.find(product => product.name === 'warranty'))
  }

  async setOffers (offers) {
    for (const item of offers) {
      if (item.quotas.length) {
        try {
          const exchangeValue = await this.getCurrencyValueExchange(item.quotas[0], this.currency)
          this.$set(this.currencyExchanges, item.id, fixPrice(exchangeValue))
        } catch (error) {
          console.error(error)
          this.$set(this.currencyExchanges, item.id, 'Error')
        }
      }
    }
    this.fields.warranties.items = offers
  }

  async setFiles (tag, warranty) {
    const { filesProcess: { saleProduct } } = this

    const offers = await this.fetchData({
      query: { name: 'find', model: 'Offer' },
      filter: { variant: { _and: [{ agreement_product: { product: { name: { _eq: 'warranty' } } } }, { active: { _eq: true } }] } },
    })

    await this.setOffers(offers)
    if (!this.backup?.saleForm) {
      this.formData.warranty = this.fields.warranties.items.find(item => item.id === warranty?.offer?.id)?.id
    }

    this.formData.isWarranty = Boolean(this.formData.warranty && this.formData.insurance)
    this.disabledFormSelection.warranty = this.formData.isWarranty
    this.formData.isTag = this.formData.isTag ?? Boolean(this.formData.warranty && this.formData.fileTag.length)
    this.disabledFormSelection.tag = this.formData.isTag

    this.formData.comment = warranty?.comment
    if (warranty?.id) {
      const warrantyFiles = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: warranty.id } },
            { parameter: { process: { id: { _eq: saleProduct } } } },
          ],
        },
        force: true,
      })
      this.formData.fileWarranty = warrantyFiles.filter(file => file.parameter.name === 'warranty') || []
      this.formData.fileExtraWarranty = warrantyFiles.filter(file => file.parameter.name === 'extra_warranty') || []
    }

    if (tag?.id) {
      const tagFiles = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: tag.id } },
            { parameter: { process: { id: { _eq: saleProduct } } } },
          ],
        },
        force: true,
      })

      this.formData.fileTag = tagFiles.filter(file => file.parameter.name === 'tag') || []
    }
    await this.setFilesData()
  }

  async findAssociatedFiles (idProcess, id) {
    if (!id) return {}
    const { isDigitalContract } = this
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
        ],
      },
      force: true,
    })

    const cav = files.filter(file => file.parameter.name === 'cav')

    if (cav.length) {
      this.saleOrder.cavValidation = {
        expirationDate: cav[0].file.expirationDate,
        validations: cav[0].validation,
      }
    }

    const report = files.filter(file => file.parameter.name === 'legal_report')

    const penalties = files.filter(file => file.parameter.name === 'penalty_certificate')

    let contract = files.filter(file => !isDigitalContract ? file.parameter.name === 'buyer_physical_contract' : file.parameter.name === 'buyer_digital_contract')
    if (!contract?.length) {
      contract = files.filter(file => file.parameter.name === 'exempt_invoice')
    }

    const buyerCard = files.filter(file => file.parameter.name === 'buyer_card')
    return {
      cav,
      report,
      contract,
      penalties,
      buyerCard,
    }
  }

  async setFilesData () {
    const {
      cav,
      contract,
      penalties,
      report,
      buyerCard,
    } = await this.findAssociatedFiles(this.filesProcess.saleOrder, this.saleOrder.id)

    this.formData.fileCav = cav?.length ? [cav[0]] : []
    this.formData.fileLegalReport = report?.length ? [report[0]] : []
    if (report?.[0]?.file?.sourceLink) {
      this.formData.linkLegalReport = report?.[0]?.file?.sourceLink
    }
    this.formData.contract = contract?.length ? [contract[0]] : []

    if (contract?.[0]?.file?.sourceLink) {
      this.formData.linkContract = contract?.[0]?.file?.sourceLink
    }
    this.formData.filePenalties = penalties?.length ? [penalties[0]] : []
    this.formData.fileLicense = buyerCard?.length ? [buyerCard[0]] : []
    this.penalties = await this.getPenalties(this.formData?.filePenalties?.[0]?.id)

    const document = await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { id: { _eq: this.saleOrder.documentId } },
      force: true,
    })

    this.formData.signers = document[0]?.interveners?.filter(interviner => interviner.field.name === 'purchase_representative').map(intervener => intervener.person) || []
    this.loaded = true
  }

  saveBackup (item = null) {
    const {
      backup,
      formData,
      panels,
      saleOrder: { deal: { lead } },
      saleOrder,
      totalPaymentsPaid,
      totalAmount,
      totalPaymentsValidated,
      totalValid,
      deal,
    } = this

    if (backup) {
      backup.lead = lead
      backup.deal = deal
      backup.saleForm = {
        ...formData,
        panels,
        saleOrder,
        totalPaymentsPaid,
        totalAmount,
        totalPaymentsValidated,
        totalValid,
      }
      backup.editPayment = item
      this.setBackup(backup)
    } else {
      this.setBackup({
        saleForm: {
          ...formData,
          panels,
          saleOrder,
          totalPaymentsPaid,
          totalAmount,
          totalPaymentsValidated,
          totalValid,
        },
        editPayment: item,
        lead,
        deal,
        client: null,
        signers: null,
        acquirer: null,
      })
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('SaleOrder', 'staff_sale_order')
    const { metadata: metadataReserve } = this.getForm('Reserve', 'reserve')
    const { fields: { config: { init: { value } } } } = metadataReserve as Form
    this.minValue = value.minValue
    this.maxDays = value.maxDays
    const { form, fields: saleFields } = metadata as Form
    this.fields.acquirer.properties = saleFields.acquirer.properties
    this.fields.signers.properties = saleFields.signers.properties
    this.fields.documentType.properties = saleFields.documentType.properties
    this.fields.linkLegalReport.properties = saleFields.linkLegalReport.properties
    this.fields.linkContract.properties = saleFields.linkContract.properties
    this.saleMetadata = metadata
    this.title = form.title

    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    const { id } = process[0] as Record<string, any>

    this.idProcess = id
  }

  @Debounce(500)
  setDetails () {
    const {
      saleMetadata,
      saleOrder,
      formData: { discount, warranty },
      transferCost,
      totalAmount,
      currencyExchanges,
      warrantyAmount,
      warrantyAmountSet,
    } = this

    const deal = saleOrder?.deal?.lead?.deals?.find(deal => deal.isSale)
    if (!deal) return

    const pos = deal.saleOrders[0].saleOrderItems.findIndex(item => item.type.name === 'discount')

    if (!pos || pos < 1) {
      deal.saleOrders[0].saleOrderItems.push(plainToInstance(SaleOrderItem, {
        amount: discount,
        type: plainToInstance(ItemType, { id: 2, name: 'discount' }),
      }))
    } else {
      this.saleOrderItemDiscountId = deal.saleOrders[0].saleOrderItems[pos].id
      deal.saleOrders[0].saleOrderItems[pos].amount = discount
    }
    deal.saleOrders[0].status = saleOrder.status
    deal.lead = saleOrder?.deal?.lead
    deal.lead.sale.stock.transferCost = transferCost
    deal.lead.sale.totalAmount = totalAmount
    deal.lead.sale.order.cavValidation = saleOrder.cavValidation
    deal.lead.sale.warrantyAmount = warrantyAmountSet || currencyExchanges?.[warranty] || fixPrice(warrantyAmount)
    deal.auto = deal.lead.sale.auto
    deal.stock.transferCost = transferCost
    deal.totalAmount = totalAmount
    deal.warrantyAmount = warrantyAmountSet || currencyExchanges?.[warranty] || fixPrice(warrantyAmount)
    deal.order._discount = discount

    this.deal = deal

    this.metadata = {
      data: deal,
      metadata: saleMetadata,
    }

    this.showDetail = (Boolean(saleOrder?.id))
  }

  async preSend () {
    const { saleOrderToContract } = this
    this.isMultiple = true
    if (saleOrderToContract) {
      this.panels = [3]
    } else {
      this.panels = [0, 1, 2]
    }

    await this.send()
  }

  checkErrorFileContent () {
    const fields = ['fileCav', 'filePenalties', 'fileLegalReport']

    for (const field of fields) {
      if (this.$refs?.[field]?.errorMessage?.length) {
        return true
      }
    }
  }

  @Debounce(500)
  async send () {
    const client = this.$refs.fieldClient
    const acquirer = this.$refs.fieldAcquirer
    const signers = this.$refs.fieldSigners
    const overComment = this.formData.comment?.length && this.formData.comment?.length > 250
    const errorMessages = this.errorSignerMessages?.length || this.errorAcquirerMessages?.length || this.checkErrorFileContent()
    const errorDiscount = this.errorDiscount?.length || this.errorDiscountPostFinancing?.length

    if (errorDiscount || this.saleIsApproved) {
      this.isMultiple = false
      return
    }
    const { saleOrderToContract, saleOrder } = this

    if (saleOrderToContract && ((acquirer && !acquirer?.$refs.form.validate()) || (signers && !signers?.$refs.form.validate()) || errorMessages)) {
      this.isMultiple = false
      return
    }

    if (!this.$refs.form.validate() || !client?.$refs.form.validate() || overComment) {
      this.isMultiple = false
      return
    }

    this.loadingForm = true

    if (!saleOrder.status.isToUpdating) {
      await this.handleSaleOrder(this)
    } else {
      await this.handleSaleOrderUpdating(this)
    }

    await this.addUpdateDiscount()

    await this.updateSaleOrderItems(saleOrder)

    this.loadingForm = false
    await this.close()
  }

  async updateSaleOrderItems (order) {
    const { deal: { stock } } = this

    if (!order?.saleOrderItems?.find(item => item.isProduct)) {
      await this.insertSalesOrderItemProduct(order.id, stock)
    }

    if (!order?.saleOrderItems?.find(item => item.isTransfer)) {
      await this.insertSalesOrderItemTransfer(order.id, stock)
    }
  }

  async addOrUpdateDiscountPostFinancing () {
    const { saleOrder, formData } = this
    if (!isValidNumber(formData.discountPostFinancing)) return

    const deal = saleOrder?.deal?.lead?.deals?.find(deal => deal.isSale)
    const pos = deal.saleOrders[0].saleOrderItems.findIndex(item => item.type.name === 'post_financing_discount')

    if (!pos || pos < 1) {
      const type = await this.fetchData({
        query: { name: 'find', model: 'ItemType' },
        filter: { name: { _eq: 'post_financing_discount' } },
      })
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          amount: formData.discountPostFinancing,
          id_sale_order: saleOrder.id,
          quantity: 1,
          id_item_type: type[0]?.id,
        },
      })
    } else {
      const discountId = deal.saleOrders[0].saleOrderItems[pos].id
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          id: discountId,
          amount: formData.discountPostFinancing,
        },
      })
    }
  }

  async addUpdateDiscount () {
    const { saleOrderItemDiscountId, formData, discountIsPending } = this

    if (discountIsPending) {
      await this.addOrUpdateDiscountPostFinancing()
      return
    }

    if (saleOrderItemDiscountId && isValidNumber(formData.discount)) {
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          id: saleOrderItemDiscountId,
          amount: formData.discount,
        },
      })
    } else if (isValidNumber(formData.discount)) {
      const discountType = await this.fetchData({
        query: { name: 'find', model: 'ItemType' },
        filter: { name: { _eq: 'discount' } },
      })
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          amount: formData.discount,
          id_sale_order: this.saleOrder.id,
          quantity: 1,
          id_item_type: discountType[0]?.id,
        },
      })
    }
  }

  get change () {
    const { formData, panels, section } = this

    return stringifySafe([formData, panels, section])
  }

  async searchPerson ({ input, type }) {
    if (!input?.length || input?.length < 2) return null

    const { saleMetadata } = this

    if (!saleMetadata) return null
    const { fields } = saleMetadata as Form

    if (!fields?.client) return
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', input, ['name_person_type'])

    const persons = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { ...query },
      force: true,
    })

    if (type === 'acquirer') {
      this.fields.acquirer.items = persons
    } else {
      const ids = this.formData.acquirer?.map(acquirer => acquirer.id)
      this.fields.signers.items = persons.filter(person => !ids.includes(person.id))
    }
  }

  async editPerson (person) {
    const { backup, formData, panels } = this

    if (!backup) {
      this.setBackup({
        saleForm: formData,
      })
    } else {
      backup.saleForm = { ...formData, panels }
      this.setBackup(backup)
    }

    await this.$router.push({ name: 'generic-person-nested', params: { model: 'SaleOrder', uid: null, id: person.id } })
  }

  async goPerson () {
    const { backup, formData, panels } = this

    if (!backup) {
      this.setBackup({ saleForm: formData })
    } else {
      backup.naturalForm = null
      backup.saleForm = { ...formData, panels }
      this.setBackup(backup)
    }

    await this.$router.push({ name: 'generic-person-nested', params: { model: 'SaleOrder', uid: null, id: null } })
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get discountIsPending () {
    const { saleOrder } = this

    if (!saleOrder) return false

    const { deal } = saleOrder

    if (!deal) return false

    const { lead: { sale } } = deal

    if (!sale) return false
    const { financial } = sale
    if (!financial) return false
    const { evaluation } = financial

    return Boolean(evaluation)
  }

  get discount () {
    const { lead: { sale } } = this
    if (!sale) return 0
    const { stock } = sale

    return stock?.price?.bonus?.amount || stock?.price?.margin || 0
  }

  get paymentError () {
    const { minValue, formData: { order }, totalPercentagePaid } = this
    this.disableSend = false
    if (Number(totalPercentagePaid) > 100) {
      this.disableSend = true
      return 'La suma de los pagos no puede ser mayor al total a cancelar'
    }

    if (!order) {
      this.disableSend = false
      return ''
    }

    const message = this.paymentValid(order) ? '' : `La suma de los pagos debe ser mayor ${fixPrice(minValue)}`

    if (message?.length) {
      this.disableSend = true
    }
    return message
  }

  paymentValid (payments) {
    const { minValue } = this
    const suma: number = payments.reduce((total, item) => {
      return total + Number(item.amount)
    }, 0)

    return suma >= minValue
  }

  setLeadInfo (lead, discount) {
    const { client, sale: { order: { discountPostFinancing } } } = lead as Lead

    if (discount?.value) {
      this.formData.discount = discount?.value
    }

    if (discountPostFinancing?.value) {
      this.formData.discountPostFinancing = discountPostFinancing.value
    }

    this.formData.client = client
    this.fields.client.items = [client]
  }

  async goToOrder () {
    const { saleOrder } = this
    if (this.backup?.paymentForm) {
      this.backup.paymentForm = null
      this.backup.client = null
    }
    this.saveBackup()

    const idDeal = saleOrder?.deal?.id ? saleOrder?.deal?.id.toString() : 'create'
    await this.$router.push({
      name: 'generic-payment',
      params: { parentModel: 'Deal', parentId: idDeal, id: 'create' },
    })
  }

  isNotReserve (payment) {
    return this.idProcess === payment?.idProcess
  }

  get bindInsurance () {
    const { fields: { insurance } } = this

    if (!insurance) return {}

    return { ...insurance.properties, items: insurance.items }
  }

  get bindBrokers () {
    const { fields: { brokers } } = this

    if (!brokers) return {}

    return { ...brokers.properties, items: brokers.items }
  }

  get bindDeductible () {
    const { fields: { deductible } } = this

    if (!deductible) return {}

    return { ...deductible.properties, items: deductible.items }
  }

  get bindWarranty () {
    const { fields: { warranties } } = this

    if (!warranties) return {}

    return { ...warranties.properties, items: warranties.items }
  }

  get bindSigners () {
    const { fields: { signers } } = this

    if (!signers) return {}

    return { ...signers.properties, items: signers.items }
  }

  get bindAcquirer () {
    const { fields: { acquirer } } = this

    if (!acquirer) return {}

    return { ...acquirer.properties, items: acquirer.items }
  }

  get disabled () {
    return false
  }

  get isDigitalContract () {
    const { formData: { documentType }, documentFilesParameters } = this
    if (!documentType) return false

    const file = documentFilesParameters.find(file => file.name === `buyer_${documentType.name}` || file.name === 'exempt_invoice')

    if (file) {
      this.setFileProperties('contract', file)
    } else {
      this.filesProperties.contract = { name: null }
    }
    this.setFileProperties('contract', file)
    return documentType?.name === 'digital_contract'
  }

  get discountAuthorized () {
    const { saleOrder, formData: { discountPostFinancing } } = this

    if (!saleOrder) return 0

    const discount = Number(discountPostFinancing) || Number(saleOrder?.discountPostFinancing?.value) || 0

    return (Number(discount) + Number(saleOrder?.discount?.value || 0))
  }

  get priceList () {
    const { saleOrder } = this

    if (!saleOrder) return 0

    return saleOrder?.product?.amount || saleOrder?.deal?.stock?.price?.amount || 0
  }

  get totalPaymentsPaid () {
    const { formData: { order } } = this

    if (!order?.length) return 0

    return order.reduce((total, item) => total + Number(item.amount), 0)
  }

  get totalPaymentsValidated () {
    const { formData: { order } } = this

    if (!order?.length) return 0

    return order.filter(item => (item.status.isClosed && item.closingReason.successful) || item?.status?.isPendingPayment).reduce((total, item) => total + Number(item.amount), 0)
  }

  get financedAmount () {
    const { saleOrder } = this

    const evaluation = saleOrder?.financings?.[0]?.evaluations.find(evaluation => evaluation.isApproved)

    let financedAmount = 0
    if (evaluation) {
      financedAmount = saleOrder?.financings?.[0].requestAmount
    }

    return financedAmount
  }

  get total () {
    const { totalPaymentsPaid, totalAmount } = this

    return totalAmount - totalPaymentsPaid
  }

  get totalValid () {
    const { totalPaymentsValidated, totalAmount } = this

    return totalAmount - totalPaymentsValidated
  }

  get totalAmount () {
    const {
      discountAuthorized: discount,
      priceList,
      transferCost,
      warrantyAmount,
      warrantyAmountSet,
      formData,
    } = this

    const calculatedDiscount = (Number(discount) || Number(formData?.discount || 0))

    return priceList + (transferCost || 0) + (warrantyAmountSet || warrantyAmount || 0) - (calculatedDiscount || 0)
  }

  get saleOrderHasInterveners () {
    return Boolean(this.saleOrder?.deal?.stock?.acquirer?.length) || Boolean(this.saleOrder.document?.acquirers?.length)
  }

  get stock () {
    return this.saleOrder?.deal?.stock
  }

  @Watch('formData.signers', { immediate: true, deep: true })
  onSignersChange (val: Person[]) {
    if (!val?.length) {
      this.errorSignerMessages = ''
      return
    }

    this.validatePersonInfo(val, 'firmante', 'errorSignerMessages')
  }

  async validatePersonInfo (persons, type, field) {
    let error = false
    for (const person of persons) {
      const address = await this.fetchData({
        query: { name: 'find', model: 'PersonAddress' },
        filter: { id_person: { _eq: person.id } },
        force: true,
      })

      if (!person?.uid && !error) {
        error = true
        this[field] = `El ${type} ${person.fullName} no tiene un RUT asociado`
      }

      if (!address?.length && !error) {
        error = true
        this[field] = `El ${type} ${person.fullName} no tiene una dirección asociada`
      }
    }

    if (!error) {
      this[field] = ''
    }
  }

  @Watch('formData.acquirer', { immediate: true, deep: true })
  onAcquirerChange (val: Person[]) {
    if (!val?.length) {
      this.errorAcquirerMessages = ''
      return
    }

    this.validatePersonInfo(val, 'adquiriente', 'errorAcquirerMessages')
  }

  get warrantyChange () {
    const { formData: { warranty }, loaded } = this

    return {
      warranty,
      loaded,
    }
  }

  @Watch('warrantyChange', { immediate: true, deep: true })
  async onWarrantyChange (val: any) {
    const warranty = this.fields.warranties.items.find(warranty => warranty.id === val.warranty)

    if (warranty) {
      this.warrantyAmount = await this.getCurrencyValueExchange(warranty?.quotas?.[0], this.currency)
    } else {
      this.warrantyAmount = undefined
    }
    this.setDetails()
  }

  get saleOrderToContract () {
    const { saleOrder, isToUpdatingAndIsContract } = this
    this.panels = [3]

    return saleOrder?.status?.isToContract || isToUpdatingAndIsContract
  }

  get isToUpdating () {
    const { saleOrder } = this

    return saleOrder?.status?.isToUpdating
  }

  get isToUpdatingAndIsContract () {
    const { saleOrder, formData } = this

    return saleOrder?.status?.isToUpdating && Boolean(formData.fileCav?.length)
  }

  get saleOrderToLegalReview () {
    const { saleOrder } = this

    return saleOrder?.status?.isLegalReview
  }

  get saleIsApproved () {
    const { saleOrder } = this

    return saleOrder?.status?.isApproved
  }

  get saleOrderToUpdateTransfer () {
    const { saleOrder, formData: { fileCav } } = this
    return (saleOrder?.status?.isToUpdating || saleOrder?.status?.isToConfirm) && Boolean(fileCav.length)
  }

  async editPayment (item) {
    this.saveBackup()

    await this.$router.push({
      name: 'generic-payment',
      params: { uid: item.id },
    })
  }

  get totalPercentagePaid () {
    const { totalAmount, totalPaymentsPaid } = this

    return ((totalPaymentsPaid * 100) / totalAmount).toFixed(2)
  }

  get totalPercentageValidate () {
    const { totalPaymentsPaid, totalPaymentsValidated } = this

    return ((totalPaymentsValidated * 100) / totalPaymentsPaid).toFixed(2)
  }

  get discountChange () {
    const { formData: { discount }, loaded, deal } = this

    return {
      discount,
      loaded,
      deal,
    }
  }

  get discountPostFinancingChange () {
    const { formData: { discountPostFinancing, discount }, loaded, deal } = this

    return {
      discountPostFinancing,
      discount,
      loaded,
      deal,
    }
  }

  @Watch('discountPostFinancingChange', { immediate: true, deep: true })
  @Debounce(500)
  onDiscountChange (val) {
    const max = val?.deal?.lead?.sale?.stock?.price?.bonuses?.[0]?.amount || val?.deal?.lead?.sale?.stock?.currentPrice?.margin

    const discount = Number(val.discountPostFinancing) + Number(val.discount)

    if (val.discountPostFinancing) {
      if (discount > Number(max)) {
        this.errorDiscountPostFinancing = `El descuento no puede ser mayor al autorizado ${fixPrice(max)}`
      } else {
        this.errorDiscountPostFinancing = ''
      }
    } else {
      if (discount > Number(max)) {
        this.errorDiscount = `El descuento no puede ser mayor al autorizado ${fixPrice(max)}`
      } else {
        this.errorDiscount = ''
      }
    }

    this.setDetails()
  }

  get signers () {
    const { formData: { signers, acquirer } } = this

    if (!signers?.length) return acquirer || []

    return signers
  }

  get saleOrderIsToConfirm () {
    const { saleOrder } = this
    return saleOrder?.status?.isToConfirm
  }

  getIcon (item) {
    if (item?.closingReason?.name === 'successful') {
      return 'mdi-eye'
    }
    return 'mdi-pencil-outline'
  }

  @Watch('formData', { immediate: true, deep: true })
  @Debounce(200)
  onFormDataChange (val) {
    const fields = ['fileCav', 'filePenalties', 'fileLegalReport']
    const { ids, fieldsWithIds } = this.processFields(fields, val)

    this.checkForDuplicates(ids, fieldsWithIds)
  }

  checkForDuplicates (ids, fieldsWithIds) {
    for (const id in ids) {
      if (ids[id] > 1) {
        for (const field of fieldsWithIds[id]) {
          this.$refs[field].errorMessage = 'El archivo está duplicado'
        }
      }
    }
  }

  processFields (fields, val) {
    const ids = {}
    const fieldsWithIds = {}

    for (const field of fields) {
      if (this.$refs[field] && this.$refs[field].errorMessage === 'El archivo está duplicado') {
        this.$refs[field].errorMessage = ''
      }
      const id = this.isArrayFiles(val[field]) ? val[field][0]?.id : val[field]?.[0]?.file?.id
      if (id) {
        const parsedId = parseToNumber(id)
        ids[parsedId] = (ids[parsedId] || 0) + 1
        if (!fieldsWithIds[parsedId]) {
          fieldsWithIds[parsedId] = []
        }
        fieldsWithIds[parsedId].push(field)
      }
    }

    return { ids, fieldsWithIds }
  }
  }
